import './basket-teaser.scss';

class BasketTeaser {
    constructor (element, options) {
        const defaults = {
            resizeHandler: null
        };

        this.settings = Object.assign({}, defaults, options);
        this.resizeHandler = options.resizeHandler;

        this.$slider = element;
        this.currentIndex = 0;
        this.teaserSlider = undefined;
    }

    resizeBasketTeaser () {
        this.initialize();
        this.resizeHandler.customFunctions.push(() => {
            this.initialize();
        });
    }

    initialize () {
        const windowWidth = window.innerWidth;

        if (windowWidth < 768) {
            if (!this.teaserSlider) {
                this.createSlider();
            }
        } else {
            this.destroySlider();
        }
    }

    createSlider () {
        const handleSlider = (Slider) => {
            this.teaserSlider = new Slider(this.$slider, {
                breakpoints: null,
                speed: 500,
                spaceBetween: 15,
                indicators: true,
                prevnext: true,
                slidesPerView: 1,
                initAttr: 'data-basket-teaser'
            });
        };

        import('../../components/slider/slider').then(({ Slider }) => {
            handleSlider(Slider);
        });
    }

    destroySlider () {
        if (this.teaserSlider) {
            this.teaserSlider.destroy();
            this.teaserSlider = undefined;
            const $swiperControls = this.$slider.querySelector('.swiper-controls');
            if ($swiperControls) {
                $swiperControls.remove();
            }
        }
    }
}

export { BasketTeaser };

window.addEventListener('content.loaded', (e) => {
    const eventDetails = e.detail;
    const $context = eventDetails.$context;

    if ($context) {
        const $basketTeaserSliders = $context.querySelectorAll('[data-basket-teaser="slider"]');

        if ($basketTeaserSliders.length > 0) {
            for (let i = 0; i < $basketTeaserSliders.length; i++) {
                const $basketTeaserSlider = new BasketTeaser($basketTeaserSliders[i], {
                    resizeHandler: window.resizeHandler
                });
                $basketTeaserSlider.resizeBasketTeaser();
            }
        }
    }
});
